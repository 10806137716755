import React, { useState, useContext, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Transition } from 'react-transition-group';
import {
    mediaQueryHOC,
    Icon,
    SpotlightWaitlistConstants,
    Constants,
    ContentProperty,
    Heading,
    Button,
    Image,
    CampaignIframeOverlayConstants,
    CheckboxInput,
    mixtureGraphQL,
    mixtureFormRegister,
    filterMethod,
    checkPattern,
    trim,
    interestitialEventPush,
    getCookieValue,
    Block,
    OnePageScrollContext,
    Label,
    updateTraitsMixtureFormRegister
} from './exportComponent';
const SpotlightWaitlightIO10 = dynamic(() => import('./../../SpotlightWaitlistBlock/SpotlightWaitlightIO10'));


const InterstitialLayout = (props) => {
    const { document: entity, isMedium, index } = props;
    const {
        title,
        body,
        ctaLabel,
        legalText,
        emailDisclaimer,
        emailDisclaimerTop,
        surveyDescription,
        formLabels,
        classNames,
        anchorId,
        backgroundAsset,
        mobileBackgroundAsset,
        screen2BackgroundAsset,
        screen2MobileBackgroundAsset,
        thankYouTitleLabel,
        thankYouBackgroundAsset,
        thankYouDescriptionLabel,
        closeModalLabel,
        thankYouCallToAction,
        thankYouCallToActionLink,
        thankYouCallToActionIconName,
        numberOfErrorsLabel,
        modalLabelFirst,
        modalLabelSecond,
        alternateThankYouTitleLabel,
        alternateThankYouDescriptionLabel,
        callToPreOrderActionLabel,
        callToPreOrderActionLink,
        spotlightiO10Campaign,
        emailExceedLimitLabel,
        registerFailedError,
        countrySelectErrorMessage,
        isImplicitTCAgree,
        isInterstitialPopup,
        rawTopicList,
        backgroundColor,
        expiryHours,
        labelFirstStep,
    } = ContentProperty(entity);

    const context = useContext(OnePageScrollContext);
    const currentIndex = context?.currentIndex || 0;
    const isActiveBlock = (index === currentIndex);

    const block = new Block(entity);
    const [isSurveySubmit, setIsSurveySubmit] = useState(true);
    const [erroneousInputs, setErroneousInputs] = useState([]);
    const [serverSideErrorMessage, setServerSideErrorMessage] = useState(false);
    const [isSecondModalOpen, setIsModalOpen] = useState(false);
    const [isFirstLegalModalOpen, setisFirstLegalModalOpen] = useState(false);
    const [isSecondLegalModalOpen, setIsSecondLegalModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isThankYouGermany, setIsThankYouGermany] = useState(false);
    const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] = useState(isImplicitTCAgree);
    const [interstitialModalOpen, setIsInterstitialModalOpen] = useState(false);
    const [isThankyouModalOpen, setIsThankyouModalOpen] = useState(false);
    const [topicOptins, setTopicOptins] = useState([]);
    const [selectedOptinId, setSelectedOptinId] = useState();
    const [selectedBrushType, setSelectedBrushType] = useState('');
    const [userData, setUserData] = useState({});
    const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const modalRef = useRef(null);
    const blockRef = useRef(null);
    const firstLegalModalRef = useRef(null);
    const [interstitialDelay, setInterstitialDelay] = useState(false);
    const [participationID, setParticipationId] = useState();
    const [isLoader, setIsLoader] = useState(false);
    const [traitsData, setTraitsData] = useState();
    const [optinsData, setOptinsData] = useState();
    const customStyles = {
        overlay: {
            backgroundColor: 'none'
        },
        content: {
            position: 'relative',
            border: 'none',
            top: '0',
            left: '0',
            right: 'auto',
            bottom: 'auto',
            padding: '0',
            marginRight: '0',
            height: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background: 'rgba(255, 255, 255, 0.5)'
        }
    };

    const customStylesLegal = {
        overlay: {
            backgroundColor: 'none'
        },
        content: {
            position: 'fixed',
            border: 'none',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            padding: '0',
            marginRight: '0',
            height: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background: 'rgba(255, 255, 255, 0.5)'
        }
    };

    const customModalInterestitalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            position: 'absolute',
            background: `linear-gradient(to right bottom, ${backgroundColor}, ${backgroundColor})`,
            border: '8px solid #fff',
            borderRadius: '0',
            fontSize: '1.1rem',
            lineHeight: '2.1rem',
            padding: '0',
            transform: isMedium ? 'translate(-50%, -47%)' : 'translate(-50%, -50%)',
            width: isMedium ? '99%' : '100%',
            minWidth: isMedium ? '99%' : '50%',
            maxWidth: isMedium ? '100%' : '900px',
            height: isMedium ? '80vh' : 'auto',
            maxHeight: isMedium ? '80vh' : SpotlightWaitlistConstants.interestitialModalMaxHeight,
            overflowX: 'hidden'
        }
    };
    const customStylesUserIntrest = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            overflowX: 'hidden',
            marginRight: '-50%',
            position: 'absolute',
            background: `linear-gradient(to right bottom, ${backgroundColor}, ${backgroundColor})`,
            transform: isMedium ? 'translate(-50%, -47%)' : 'translate(-50%, -45%)',
            padding: '0',
            width: isMedium ? '90%' : '100%',
            minWidth: isMedium ? '90%' : '50%',
            maxWidth: isMedium ? '100%' : '900px',
            border: '8px solid #fff',
            borderRadius: '0',
            height: 'auto',
            maxHeight: isMedium ? '80vh' : SpotlightWaitlistConstants.interestitialUserInterestModalMaxHeight
        },
    };
    const customStylesInterestitialLegalModal = {
        overlay: {
            backgroundColor: 'none'
        },
        content: {
            top: "0",
            left: "0",
            right: "0",
            inset: isMedium ? "50% auto auto 50%": "20% auto auto 50%",
            marginRight: '-50%',
            position: 'absolute',
            border: 'none',
            bottom: isMedium ? '5%' : 'auto',
            maxWidth: isMedium ? '80%' : '100%',
            width: isMedium ? '80%' : '50%',
            height: '300px',
            padding: '0',
            boxShadow: 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background: `linear-gradient(to right bottom, ${backgroundColor}, ${backgroundColor})`,
            transform: isMedium ? 'translate(-50%, -50%)' : 'translate(-50%, 10%)',
            zIndex: SpotlightWaitlistConstants.interestitialSecondModalZIndex
        }
    };

    useEffect(() => {
        const spotlightThankYouGermanySelector = document.getElementsByClassName('thank-you-de');
        // Thank you page
        if (spotlightThankYouGermanySelector.length > 0) {
            setIsThankYouGermany(true);
        } else {
            if (document.querySelector('.js-modal-container')) {
                Modal.setAppElement('.js-modal-container');
            }
            setTimeout(() => {
                setInterstitialDelay(true);
            }, 10000);
        }
    }, []);

    useEffect(() => {
        const errors = document.querySelector('.ob-focus-error');
        if (!errors) {
            return;
        }
        errors.focus();
    }, [erroneousInputs, serverSideErrorMessage]);

    useEffect(() => {
        if (interstitialDelay && isInterstitialPopup && !localStorage.getItem(CampaignIframeOverlayConstants.userDataCollectedIntersistialPopUp) && !getCookieValue(CampaignIframeOverlayConstants.disableIntersistialPopUpCookie)) {
            setIsInterstitialModalOpen(true);
            const date = new Date();
            date.setHours(parseInt(expiryHours)); // for one day validaity
            const expires = `expires=${date.toUTCString()}`;
            document.cookie = `${CampaignIframeOverlayConstants.disableIntersistialPopUpCookie}=ok; ${expires}; path=/`;
            sessionStorage.removeItem(CampaignIframeOverlayConstants.interestitialUserDetail);
            interestitialEventPush(
                CampaignIframeOverlayConstants?.eventCategory,
                CampaignIframeOverlayConstants?.startSurveyEvent,
                CampaignIframeOverlayConstants?.eventLabel);
            interestitialEventPush(
                CampaignIframeOverlayConstants?.eventCategory,
                CampaignIframeOverlayConstants?.questionSurveyAction,
                CampaignIframeOverlayConstants?.questionSurveyLabel);
        } else {
            setIsInterstitialModalOpen(false);
        }
    }, [interstitialDelay]);

    useEffect(() => {
        if (serverSideErrorMessage && isInterstitialPopup && isSecondModalOpen) {
            setIsModalOpen(false);
            const userDetails = readUserDetails();
            setUserData(userDetails);
        }
    }, [serverSideErrorMessage]);

    useEffect(() => {
        !getCookieValue(CampaignIframeOverlayConstants.disableIntersistialPopUpCookie) && mixtureGraphQL().then((response) => {
            let traitsObj = filterMethod(response?.consumerActions, 'trait');
            let optinsObj = filterMethod(response?.consumerActions, 'optIn');
            setTraitsData(traitsObj);
            setOptinsData(optinsObj);
        }).catch((error) => {
            console.error(error);
        })
    }, []);

    const closeInterestitialEventPush = (isSuccess) => {
        if (isSuccess) {
            interestitialEventPush(
                CampaignIframeOverlayConstants?.eventCategory,
                CampaignIframeOverlayConstants?.completeSurveyEvent,
                CampaignIframeOverlayConstants?.eventCloseLabelWithSurveyResponse
            )
        } else {
            interestitialEventPush(
                CampaignIframeOverlayConstants?.eventCategory,
                CampaignIframeOverlayConstants?.eventCloseAction,
                CampaignIframeOverlayConstants?.eventCloseLabelWithoutNoResponse
            )
        }
    };

    const closeModal = () => {
        closeLegalModal();
        setIsThankyouModalOpen(false);
        setIsInterstitialModalOpen(false);
        setIsModalOpen(false);
        sessionStorage.removeItem(CampaignIframeOverlayConstants.interestitialUserDetail);
        if (isRegistrationSuccess) {
            closeInterestitialEventPush(true);
        } else {
            closeInterestitialEventPush(false);
        }
    };

    const changeUserData = (evt, id) => {
        const userDetail = { ...userData, [id]: evt?.target?.value }
        if (id === 'whyYouShouldWin' && evt?.target?.value != null) {
            setIsSurveySubmit(false)
        }
        setUserData(userDetail);
        if (isError) {
            const inputElement = document.getElementById(id);
            inputElement.classList.remove('--error');
            const updatedErrorInputs = erroneousInputs.filter(errorInput => errorInput?.id !== id);
            setErroneousInputs(updatedErrorInputs);
        }
        serverSideErrorMessage && setServerSideErrorMessage(false);
        errorMessage && setErrorMessage('');
    }

    const handleBrushSelection = (selectedBrush) => {
        setErrorMessage('');
        if (selectedBrushType === selectedBrush) {
            setSelectedBrushType('');
            setSelectedOptinId(0);
        } else {
            const optinId = getSelectedOptinId(selectedBrush);
            setSelectedOptinId(optinId);
            setSelectedBrushType(selectedBrush);
        }
    };

    const isMandatoryBrushTypeNotSelected = () => countrySelectErrorMessage && !selectedBrushType;

    const handleSubmit = async event => {
        event.preventDefault();
        setIsSubmitting(true);
        let isTestBClassPresent = document.getElementsByClassName('ob-pop-up-heading')?.[0]?.getAttribute('class')?.includes('Test-B') ? { testVariant: 'B' } : document.getElementsByClassName('ob-pop-up-heading')?.[0]?.getAttribute('class')?.includes('Test-A') ? { testVariant: 'A' } : { testVariant: 'default' }
        const form = event.target;
        let termsAndConditionsChecked;
        if (isImplicitTCAgree) {
            termsAndConditionsChecked = isImplicitTCAgree;
            setHasAcceptedTermsAndConditions(isImplicitTCAgree);
        } else if (!isInterstitialPopup && !document.querySelector('#TermsAndConditions')) {
            termsAndConditionsChecked = document.querySelector('#TermsAndConditions') != null && document.querySelector('#TermsAndConditions').checked;
            setHasAcceptedTermsAndConditions(termsAndConditionsChecked);
        } else {
            termsAndConditionsChecked = hasAcceptedTermsAndConditions;
        }
        if (isMandatoryBrushTypeNotSelected()) {
            setErrorMessage(countrySelectErrorMessage);
        }
        const inputs = form.querySelectorAll('input, select');
        let errors = [];

        for (let i = 0; i < inputs.length; i++) {
            inputs[i].classList.remove('--error');
            if (inputs[i].hasAttribute('required')) {
                if (inputs[i].value.length === 0) {
                    errors.push({ id: inputs[i].getAttribute('id') });
                    inputs[i].classList.add('--error');
                } else if (inputs[i].hasAttribute('pattern')) {
                    if (checkPattern(inputs[i])) {
                        errors.push({ id: inputs[i].getAttribute('id') });
                        inputs[i].classList.add('--error');
                    }
                }
            } else if (inputs[i].value.length > 0 && inputs[i].hasAttribute('pattern')) {
                if (checkPattern(inputs[i])) {
                    errors.push({ id: inputs[i].getAttribute('id') });
                    inputs[i].classList.add('--error');
                }
            }
        }
        const email = form?.email?.value;
        if (errors.length === 0 && isInterstitialPopup && email && selectedBrushType) {
            const firstName = trim(form?.firstNameSpotlight?.value);
            let arguObj = {
                firstName: firstName,
                email: email,
                selectedBrushType: selectedBrushType,
                traitsData: traitsData,
                optinsData: optinsData,
                emailDisclaimerTop: legalText,
                emailOptInStatus: hasAcceptedTermsAndConditions
            }
            await mixtureFormRegister(arguObj)
                .then((response) => {
                    if (response?.status === 'SUCCESS') {
                        setIsLoader(false);
                        setIsRegistrationSuccess(true);
                        setErroneousInputs([]);
                        setIsModalOpen(true);
                        setIsThankyouModalOpen(true);
                        setParticipationId(response['participationId'])
                        if (typeof (window.dataLayer) !== 'undefined') {
                            interestitialEventPush('event_crm_action', 'event_profile_email_signup', 'interstitial_signup');
                            interestitialEventPush(CampaignIframeOverlayConstants?.eventCategory, CampaignIframeOverlayConstants?.questionSurveyAction, `interstitial_traitBrushType_${selectedBrushType}`);
                        }
                    } else {
                        setIsSubmitting(false);
                        setIsLoader(false);
                        setServerSideErrorMessage(registerFailedError);
                        return response?.json();
                    }
                }).then((response) => {
                    if (response && response.status && response.code && response.code === 'ALREADY_PARTICIPATED') {
                        setServerSideErrorMessage(emailExceedLimitLabel);
                    }
                }).catch((error) => {
                    console.log('error log:', error);
                    setServerSideErrorMessage(registerFailedError);
                    setIsSubmitting(false);
                    setIsLoader(false);
                });
            setIsSubmitting(false);
        } else {
            !selectedBrushType && setErrorMessage(countrySelectErrorMessage);
            setErroneousInputs([...errors]);
            setIsSubmitting(false);
            setIsLoader(false)
        }
    };

    const getSelectedOptinId = (selectedBrushType) => {
        for (let i = 0; i < topicOptins.length; i++) {
            if (topicOptins[i]?.optiontext === (selectedBrushType && selectedBrushType.trim())) {
                return topicOptins[i]?.optionId;
            }
        }
        return null;
    }

    const readUserDetails = () => {
        return JSON.parse(sessionStorage.getItem(CampaignIframeOverlayConstants.interestitialUserDetail));
    }

    const duration = 600;
    const isError = erroneousInputs.length > 0;
    const closeLegalModal = () => {
        setisFirstLegalModalOpen(false);
        setIsSecondLegalModalOpen(false);
    };

    const setLegalModals = () => {
        if (blockRef.current) {
            let firstModalId = blockRef.current.querySelector('#openFirstLegalModalSpotlight');
            if (firstModalId) {
                firstModalId && firstModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setisFirstLegalModalOpen(true);
                })
            }

            let secondModalId = blockRef.current.querySelector('#openSecondLegalModalSpotlight');
            if (secondModalId) {
                secondModalId && secondModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setIsSecondLegalModalOpen(true);
                })
            }
        }
    };

    const renderFormLabel = (isFirstStep, ctaText, className = '') => {
        let formLabelsCopy = [];
        if (isFirstStep) {
            formLabelsCopy = formLabels?.slice(0, CampaignIframeOverlayConstants.formLabelFirstStep);
        } else {
            formLabelsCopy = formLabels?.slice(CampaignIframeOverlayConstants.formLabelFirstStep, formLabels?.length);
        }
        return <form className={`ob-spotlight-waitlist__form ${className}`} onSubmit={handleSubmit} noValidate>
            <ul className={'ob-spotlight-waitlist__form-list'}>
                {
                    formLabelsCopy?.map((formLabel, formLabelIndex) => (
                        <li className={'ob-spotlight-waitlist__form-list-item'} key={'formLabel-' + formLabelIndex}>
                            {(
                                formLabel.fields.type === 'textArea' ?
                                    <textarea cols={51} rows={3}
                                        className={'ob-spotlight-waitlist__form-input'}
                                        placeholder={formLabel.fields.label.fields.text}
                                        id={formLabel?.fields?.id}
                                        value={userData && userData[formLabel?.fields?.id]}
                                        onChange={(evt) => changeUserData(evt, formLabel?.fields?.id)}
                                        maxLength={formLabel?.fields?.maxLength}
                                        name={formLabel?.fields?.id} />
                                    :
                                    <input
                                        type={formLabel.fields.type}
                                        pattern={formLabel?.fields?.validation?.fields?.pattern}
                                        required={formLabel?.fields?.validation?.fields?.required}
                                        id={formLabel?.fields?.id}
                                        name={formLabel?.fields?.id}
                                        value={userData && userData[formLabel?.fields?.id]}
                                        maxLength={formLabel?.fields?.maxLength}
                                        onChange={(evt) => changeUserData(evt, formLabel?.fields?.id)}
                                        className={'ob-spotlight-waitlist__form-input'} placeholder={formLabel.fields.label.fields.text} />
                            )}

                            {formLabel.fields.fieldMention &&
                                <p className={'ob-spotlight-waitlist__form-mention'}>
                                    {formLabel.fields.fieldMention}
                                </p>
                            }

                            {erroneousInputs.length > 0 &&
                                erroneousInputs?.map((error, index) => {
                                    return error.id === formLabel.fields.id && formLabel?.fields?.validation?.fields?.errorMessage &&
                                        <p key={index} className={'ob-spotlight-waitlist__form-error'}>
                                            {formLabel.fields.validation.fields.errorMessage}
                                        </p>
                                })
                            }
                        </li>
                    ))
                }
                {isFirstStep && emailDisclaimerTop && !isImplicitTCAgree &&
                    <li className={'ob-spotlight-waitlist__form-list-item legal-text-content'} >
                        <p dangerouslySetInnerHTML={{ __html: emailDisclaimerTop }}></p>
                    </li>
                }
                {isFirstStep && legalText && !isImplicitTCAgree &&
                    <li className={'ob-spotlight-waitlist__form-list-item legal-text-checkbox'} >
                        <CheckboxInput
                            label={`<p>${legalText}</p>`}
                            name={SpotlightWaitlistConstants.legalText}
                            value={SpotlightWaitlistConstants.legalText}
                            checked={hasAcceptedTermsAndConditions}
                            onChange={() => setHasAcceptedTermsAndConditions(!hasAcceptedTermsAndConditions)}
                        />
                        {emailDisclaimer &&
                            <div className='legal-text-wrapper'>
                                <p dangerouslySetInnerHTML={{ __html: emailDisclaimer }}></p>
                            </div>
                        }
                    </li>
                }
                <li className={cx('ob-spotlight-waitlist__form-list-item cta-section', { 'margin-cta-section': isError })} >
                    <Button
                        type={SpotlightWaitlistConstants.submit}
                        className={cx('cta-button')}
                        whiteTheme={true}
                        disabled={isFirstStep ? isSubmitting : isSurveySubmit}>
                        {`${ctaText}`}
                    </Button>
                    {isLoader &&
                        <span className={'ob-button__spinner'}></span>
                    }
                </li>
            </ul>
        </form>
    };

    const renderUserDetailForm = () => {
        return (<div
            ref={blockRef} className={''} id={anchorId} >
            {isMedium &&
                <div className={'ob-spotlight-waitlist__mobile-img'}>
                    <Image image={mobileBackgroundAsset} />
                </div>
            }
            <Image image={(backgroundAsset && !isMedium) ? backgroundAsset : mobileBackgroundAsset} customStyles={{ backgroundSize: 'cover !important', backgroundRepeat: 'no-repeat', padding: '0', minHeight: '940px' }}>
                <div>
                    <div className={'ob-spotlight-waitlist__wrapper'}>
                        <Heading className={'ob-spotlight-waitlist__title'}>{title}</Heading>
                        <div className={'ob-spotlight-waitlist__body'} dangerouslySetInnerHTML={{ __html: body }} />
                        <p className={'ob-spotlight-waitlist__label-first-step'}>{labelFirstStep}</p>
                        <p className={'ob-spotlight-waitlist__label-which-brush-used'}>{thankYouCallToAction}</p>
                        {(errorMessage || serverSideErrorMessage) &&
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            <div tabIndex='0' className={'ob-focus-error ob-spotlight-waitlist__system-error'}>
                                {errorMessage || serverSideErrorMessage}
                            </div>
                        }
                        {rawTopicList &&
                            <div className={'ob-spotlight-survey__form-list'}>
                                {
                                    rawTopicList?.map((topic, topicIndex) => {
                                        const value = topic?.fields?.value;
                                        return <div className={'ob-spotlight-survey__form-list-item'} key={'formLabel-' + topicIndex}>
                                            <button disabled={isSubmitting} className={`ob-spotlight-survey__brush-type ${value === selectedBrushType ? 'ob-spotlight-survey__brush-type-selected' : ''}`} value={value} onClick={() => handleBrushSelection(value)}>
                                                <Image image={topic?.fields?.icon} />
                                                <span dangerouslySetInnerHTML={{ __html: topic?.fields?.label }} />
                                            </button>
                                        </div>
                                    })
                                }
                            </div>
                        }
                        {renderFormLabel(true, ctaLabel?.fields?.text)}
                    </div>
                </div>
            </Image>
        </div>);
    }

    const registerLegalModalOpen = () => {
        return setTimeout(() => {
            return setLegalModals();
        }, 500)
    };

    const renderThankYouScreen = () => {
        return (<div ref={blockRef} className={''} id={anchorId} >
            <Image image={(thankYouBackgroundAsset && !isMedium) ? thankYouBackgroundAsset : screen2MobileBackgroundAsset} customStyles={{ backgroundSize: 'cover !important', backgroundRepeat: 'no-repeat', padding: '20px', minHeight: '550px' }}>
                <div className={'ob-interstitial-thankyou__wrapper'}>
                    <div className={'ob-interstitial-thankyou__wrapper-inner'}>
                        <Heading className={'ob-interstitial-thankyou__title'}>{thankYouTitleLabel}</Heading>
                        {thankYouDescriptionLabel && <p className={'ob-interstitial-thankyou__body'} dangerouslySetInnerHTML={{ __html: thankYouDescriptionLabel }} />}
                    </div>
                </div>
            </Image>
        </div>);
    }

    if (isActiveBlock && !isInterstitialPopup) {
        setLegalModals();
    }
    if (spotlightiO10Campaign) {
        return <SpotlightWaitlightIO10 block={block} backgroundAsset={backgroundAsset} customStylesLegal={customStylesLegal} setLegalModals={setLegalModals} />
    }
    if (isInterstitialPopup) {
        return (
            interstitialDelay && <div className="ob-campaignoverlay">
                <Modal
                    isOpen={interstitialModalOpen}
                    contentLabel={anchorId}
                    aria={{ modal: true }}
                    portalClassName="interestitial-popup"
                    onAfterOpen={registerLegalModalOpen}
                    onRequestClose={closeModal} closeTimeoutMS={250}
                    style={isSecondModalOpen ? customStylesUserIntrest : customModalInterestitalStyles}>
                    <div className='ob-campaignoverlay-modal'>
                        <button aria-label={closeModalLabel} onClick={closeModal} className={'ob-spotlight-waitlist__close-modal'} type='button'>
                            <Icon name={SpotlightWaitlistConstants.close} size='1.4' color={Constants.white} />
                        </button>
                        {
                            isThankyouModalOpen ? renderThankYouScreen() : renderUserDetailForm()
                        }
                    </div>
                </Modal>

                {(modalLabelFirst || modalLabelSecond) &&
                    <Modal
                        isOpen={isFirstLegalModalOpen || isSecondLegalModalOpen}
                        style={customStylesInterestitialLegalModal}
                        onRequestClose={closeLegalModal}
                        closeTimeoutMS={250}
                        overlayClassName={'event_button_click'}
                        ref={firstLegalModalRef}
                        portalClassName="interestitial-popup-legal"
                    >
                        <div className='ob-spotlight-waitlist__modal-container-legal'>

                            {isFirstLegalModalOpen && modalLabelFirst &&
                                <p dangerouslySetInnerHTML={{ __html: modalLabelFirst }}></p>
                            }

                            {isSecondLegalModalOpen && modalLabelSecond &&
                                <p dangerouslySetInnerHTML={{ __html: modalLabelSecond }}></p>
                            }

                            <button className='event_close_window ob-modal-close'
                                onClick={closeLegalModal}
                                aria-label={closeModalLabel} type='button'>
                                <Icon name='close' size='2.4' color='#000' />
                            </button>

                        </div>
                    </Modal>
                }
            </div>
        )
    } else if (!isInterstitialPopup) {
        return (
            <Transition in={index === undefined} timeout={duration}>
                {() => (
                    <div
                        ref={blockRef}
                        className={`ob-spotlightContentBlock ob-spotlight-waitlist js-modal-container ${classNames}`}
                        id={anchorId}
                        data-isactiveblock={isActiveBlock} >
                        {(isMedium && !isThankYouGermany) &&
                            <div className={'ob-spotlight-waitlist__mobile-img'}>
                                <Image image={mobileBackgroundAsset ? mobileBackgroundAsset : undefined} />
                            </div>
                        }
                        <Image image={(backgroundAsset && !isMedium) ? backgroundAsset : (isThankYouGermany ? mobileBackgroundAsset : undefined)} customStyles={{ backgroundSize: 'cover', backgroundPositionY: '-15px', backgroundRepeat: 'no-repeat' }}>
                            <div className={'ob-spotlight-waitlist__wrapper'}>
                                <Heading className={'ob-spotlight-waitlist__title'}>{title}</Heading>
                                <div className={'ob-spotlight-waitlist__body'}>{body}</div>

                                {(isThankYouGermany && (thankYouCallToAction && thankYouCallToActionLink)) &&
                                    <ObLink href={thankYouCallToActionLink} className="white ob-spotlight-waitlist__link-de" icon="chevronRight" roundedIcon="blue">
                                        <Icon name="chevronRight" roundedIcon="blue" />
                                        {thankYouCallToAction}
                                    </ObLink>
                                }

                                {serverSideErrorMessage &&
                                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                                    <div tabIndex='0' className={'ob-focus-error ob-spotlight-waitlist__form-error'}>
                                        {serverSideErrorMessage}
                                    </div>
                                }

                                {erroneousInputs.length > 0 &&
                                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                                    <div tabIndex='0' className={'ob-focus-error ob-spotlight-waitlist__form-error'}>
                                        <Label label={numberOfErrorsLabel} tokens={{ numErrors: erroneousInputs.length, plural: erroneousInputs.length > 1 ? 's' : '' }} />
                                    </div>
                                }

                                {(isActiveBlock && formLabels) &&
                                    <form className={'ob-spotlight-waitlist__form'} onSubmit={handleSubmit} noValidate>
                                        {(process.env.MARKET != 'en-us') &&
                                            <div className={'ob-spotlight-waitlist__legal-text ob-spotlight-waitlist__legal-text-not-US'}
                                                dangerouslySetInnerHTML={{ __html: legalText }}></div>
                                        }
                                        <button aria-label={ctaLabel?.fields?.text}

                                            type={SpotlightWaitlistConstants.submit}
                                            className={`ob-button event_profile_email_signup${process.env.MARKET != 'en-us' ? ' not-us' : ''}`}
                                            data-action-detail={`${ctaLabel?.fields?.text} submit`}
                                            disabled={isSubmitting}>
                                            {ctaLabel?.fields?.text}
                                            {isSubmitting &&
                                                <span className={'ob-button__spinner'}></span>
                                            }
                                        </button>
                                    </form>
                                }

                                {callToPreOrderActionLink && callToPreOrderActionLabel &&
                                    <ObLink isExternalLink={true} href={callToPreOrderActionLink} className="ob-button btn-preorder not-us ">
                                        <span className="span-preorder">{`${callToPreOrderActionLabel}`}</span>
                                    </ObLink>
                                }

                                {(process.env.MARKET == 'en-us') &&
                                    <div className={'ob-spotlight-waitlist__legal-text'} dangerouslySetInnerHTML={{ __html: legalText }}></div>
                                }

                            </div>
                        </Image>
                        <Modal
                            isOpen={isSecondModalOpen}
                            onRequestClose={closeModal}
                            closeTimeoutMS={250}
                            style={customStyles}
                            ref={modalRef}
                            className={'ob-thank-you-modal'}
                        >
                            <div className={'ob-spotlight-waitlist__modal-container'}>
                                {!isMedium &&
                                    <div className={'ob-spotlight-waitlist__modal-content'}>
                                        <Image image={thankYouBackgroundAsset} />
                                    </div>
                                }
                                <div className={'ob-spotlight-waitlist__modal-text-container ob-spotlight-waitlist__modal-content'}>

                                    {(!hasAcceptedTermsAndConditions && process.env.MARKET == 'de-de') ? (
                                        <div>
                                            <Heading>{alternateThankYouTitleLabel}</Heading>
                                            <p className={'ob-spotlight-waitlist__modal-text-content'}>{alternateThankYouDescriptionLabel}</p>
                                        </div>
                                    ) : (
                                        <div>
                                            <Heading>{thankYouTitleLabel}</Heading>
                                            <p className={'ob-spotlight-waitlist__modal-text-content'}>{thankYouDescriptionLabel}</p>
                                        </div>
                                    )
                                    }

                                    {(thankYouCallToAction && thankYouCallToActionLink) &&
                                        <p className={'ob-spotlight-waitlist__modal-cta-content'}>
                                            <a className={'ob-spotlight-waitlist__modal-cta'}
                                                href={thankYouCallToActionLink}>
                                                {thankYouCallToActionIconName &&
                                                    <Icon name={thankYouCallToActionIconName}
                                                        size={'1.4'}></Icon>
                                                }
                                                {thankYouCallToAction}
                                            </a>
                                        </p>
                                    }


                                </div>
                                {isMedium &&
                                    <div>
                                        <Image image={thankYouBackgroundAsset} />
                                    </div>
                                }
                                <button aria-label={closeModalLabel} onClick={closeModal} className={'ob-spotlight-waitlist__close-modal'} type='button'>
                                    <Icon name={SpotlightWaitlistConstants.close} size='2.4' />
                                </button>
                            </div>
                        </Modal>

                        {(modalLabelFirst || modalLabelSecond) &&
                            <Modal
                                isOpen={isFirstLegalModalOpen || isSecondLegalModalOpen}
                                style={customStylesLegal}
                                onRequestClose={closeLegalModal}
                                closeTimeoutMS={250}
                                overlayClassName={'event_button_click'}
                                ref={firstLegalModalRef}
                            >
                                <div className='ob-spotlight-waitlist__modal-container-legal'>

                                    {isFirstLegalModalOpen && modalLabelFirst &&
                                        <p dangerouslySetInnerHTML={{ __html: modalLabelFirst }}></p>
                                    }

                                    {isSecondLegalModalOpen && modalLabelSecond &&
                                        <p dangerouslySetInnerHTML={{ __html: modalLabelSecond }}></p>
                                    }

                                    <button className='event_close_window ob-modal-close'
                                        onClick={closeLegalModal}
                                        aria-label={closeModalLabel} type='button'>
                                        <Icon name='close' size='2.4' color='#000' />
                                    </button>

                                </div>
                            </Modal>
                        }
                    </div>
                )}
            </Transition>
        )
    } else {
        return null;
    }
}

export default mediaQueryHOC(InterstitialLayout);

InterstitialLayout.propTypes = {
    document: PropTypes.object,
    isMedium: PropTypes.bool,
    index: PropTypes.any
};